<template lang="pug">
div
  slot
    i18n.alert-how-to-fix(
      v-if="getAlertTypeWithV3(alert.type) === 'InsertCodeV3Missing_shopify'"
      path="campaignAlerts.InsertCodeV3Missing_shopify.howToFix"
      tag="small"
    )
      a(href="javascript:void(0);" @click="toggleShopifyAppExtension(shop.myshopify_domain, true)") {{ $t('campaignAlerts.InsertCodeV3Missing_shopify.howToFix_link') }}

    small.alert-how-to-fix.font-size-0--75(
      v-else
      v-html="$t(`campaignAlerts.${getAlertTypeWithV3(alert.type)}.howToFix`, options)"
    )
</template>
<script>
  import userAlert from '@/mixins/userAlert';
  import userAlertFixes from '@/mixins/userAlertFixes';
  import shopifyAppExtension from '@/mixins/shopifyAppExtension';

  export default {
    mixins: [userAlert, userAlertFixes, shopifyAppExtension],
    props: {
      alert: {
        type: Object,
        required: true,
      },
      campaignId: {
        type: Number,
        required: true,
      },
      userId: {
        type: Number,
        required: true,
      },
      campaign: {
        type: Object,
        required: true,
      },
    },
    computed: {
      options() {
        return this.getAlertOptions({
          alert: this.alert,
          userId: this.userId,
          campaignId: this.campaignId,
          campaign: this.campaign,
        });
      },
    },
  };
</script>
